import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.scss";
import ReactGA from "react-ga";
import { DownArrowIcon } from "./components/DownArrowIcon";
import { SectionDivider } from "./components/SectionDivider";
import preview01 from "./assets/preview01.svg";
import preview02 from "./assets/preview02.svg";
import layoutbuilderPreview from "./assets/layoutbuilderpreview.png";
import seriesPreview from "./assets/seriespreview.png";
import membershipsImg from "./assets/memberships.svg";
import { OverlayPreviews } from "./components/OverlayPreviews";

import fbImage from "./assets/socialicons/fb.svg";
import twitterImage from "./assets/socialicons/twitter.svg";
import discordImage from "./assets/socialicons/discord.svg";
import twitchImage from "./assets/socialicons/twitch.svg";
import youtubeImage from "./assets/socialicons/youtube.svg";

function App() {
  /*useEffect(() => {
    fetch("http://racelabapps-updates.surge.sh/latest.yml", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/text",
      },
    }).then((res) => {
      console.log(res);
    });
  }, []);*/

  const downloadAppUrl = "https://racelab.app/RacelabApps-installer.exe";
  const discordUrl = "https://discordapp.com/invite/h4Sc4M7";
  const fbUrl = "https://www.facebook.com/racelabpage";
  const twitterUrl = "https://twitter.com/RaceLab3";
  const twitchUrl = "https://www.twitch.tv/racelab";
  const youtubeUrl = "https://www.youtube.com/c/racelabtv";

  const trackClick = (button: string) => {
    ReactGA.event({
      category: "Click",
      action: `${button} link click`,
      label: "Click",
    });
  };

  useEffect(() => {
    ReactGA.initialize("UA-153629789-3", {
      testMode: false,
      titleCase: false,
    });
    ReactGA.set({ checkProtocolTask: false });
    ReactGA.set({ checkStorageTask: false });
    ReactGA.set({ appId: "racelablanding" });
    ReactGA.set({ appName: "RacelabLanding" });
  }, []);

  return (
    <>
      <div className="app-section section01 nopaddingtop">
        <div className="contents">
          <img src={logo} className="logo" title="racelab logo" alt="racelab logo" />

          <h1>Free iRacing overlays - racelab</h1>
          <p className="sectionheader">
            <strong>
              Get started with the best iRacing overlays for free. Packed with amazing features for racers, streamers
              and VR support.
            </strong>
          </p>
          <p className="sectionnote">
            <strong>premium options for additional customizations and overlays</strong>
          </p>
          <div className="downloadbutton-cont">
            <a href={downloadAppUrl} className="downloadbutton" onClick={() => trackClick("download app")}>
              Download Racelab
            </a>
          </div>
          <a href={discordUrl} className="jointodiscordlink" onClick={() => trackClick("discord")}>
            join our discord community
          </a>

          <div className="pageDownArrow-cont">
            <DownArrowIcon className="pageDownArrow" />
          </div>
        </div>
      </div>

      <div className="app-section section02">
        <SectionDivider className="pageSectionDivider" />
        <p className="sectionheader uppercase">
          <strong>iracing overlays</strong>
        </p>
        <p className="sectionnote">
          <strong>Clean, minimal and easy to read Overlays with great customization to fit all your needs.</strong>
        </p>
        <div>
          <img src={preview01} title="Racelab app game preview" width="100%" alt="Racelab app game preview" />
        </div>
      </div>

      <div className="app-section section03">
        <SectionDivider className="pageSectionDivider" />
        <p className="sectionheader uppercase">
          <strong>racelab app</strong>
        </p>
        <p className="sectionnote">
          <strong>it's easy to customize your overlays with our live preview and easy customization options.</strong>
        </p>
        <div>
          <img src={preview02} width="100%" alt="Racelab app preview" title="Racelab app preview" />
        </div>
      </div>

      <div className="app-section section02">
        <SectionDivider className="pageSectionDivider" />
        <p className="sectionheader uppercase">
          <strong>racelab overlay previews</strong>
        </p>
        <p className="sectionnote">
          <strong>
            we have overlays for all your needs, wether your just racing, streaming or in VR. take a look.
          </strong>
        </p>
        <OverlayPreviews />
      </div>

      <div className="app-section section03">
        <SectionDivider className="pageSectionDivider" />
        <p className="sectionheader uppercase">
          <strong>racelab app - layout builder</strong>
        </p>
        <p className="sectionnote">
          <strong>
            Layouts are a powerful tool to customize overlays for your needs.
            <br />
            Create your custom layout, define what overlays and where they should show up depending on series, session
            or car you are in.
          </strong>
        </p>
        <div>
          <img
            src={layoutbuilderPreview}
            width="70%"
            style={{ display: "flex", margin: "auto" }}
            alt="Racelab app layout builder preview"
            title="Racelab app layout builder preview"
          />
        </div>
      </div>

      <div className="app-section section02">
        <SectionDivider className="pageSectionDivider" />
        <p className="sectionheader uppercase">
          <strong>racelab app - series</strong>
        </p>
        <p className="sectionnote">
          <strong>
            Know what is the best laptime of the different series in iRacing. <br />
            The series module provide many different statistics about series, car laptimes and more...
            <br />
            Challenge yourself and your friends in the series module. See your and your friend best laptimes, in a
            visualized and clean way.
            <br />
          </strong>
        </p>
        <div>
          <img
            src={seriesPreview}
            title="Racelab app series preview"
            width="70%"
            style={{ display: "flex", margin: "auto" }}
            alt="Racelab app series preview"
          />
        </div>
      </div>

      <div className="app-section section01">
        <SectionDivider className="pageSectionDivider" />

        <p className="sectionheader uppercase">
          <strong>racelab membership</strong>
        </p>

        <img
          src={membershipsImg}
          className="membershippic"
          alt="membership prices"
          title="membership prices"
          style={{ marginTop: "5.8em" }}
        />
      </div>

      <div className="app-section section02" style={{ height: "50em", minHeight: "auto" }}>
        <SectionDivider className="pageSectionDivider" />
        <p className="sectionheader uppercase">
          <strong>Join our community on discord for more!</strong>
        </p>

        <div className="downloadbutton-cont" style={{ marginTop: "1.5em" }}>
          <a href={discordUrl} className="downloadbutton" onClick={() => trackClick("discord")}>
            Join to Discord
          </a>
        </div>

        <p className="sectionnote" style={{ marginTop: "1.6em" }}>
          <strong>follow racelab</strong>
        </p>
        <div className="socialicons">
          <a href={fbUrl} onClick={() => trackClick("facebook")}>
            <img src={fbImage} alt="facebook link" title="facebook link" />
          </a>
          <a href={twitterUrl} onClick={() => trackClick("twitter")}>
            <img src={twitterImage} alt="twitter link" title="twitter link" />
          </a>
          <a href={twitchUrl} onClick={() => trackClick("twitch")}>
            <img src={twitchImage} alt="twitch link" title="twitch link" />
          </a>
          <a href={discordUrl} onClick={() => trackClick("discord")}>
            <img src={discordImage} alt="discord link" title="discord link" />
          </a>
          <a href={youtubeUrl} onClick={() => trackClick("youtube")}>
            <img src={youtubeImage} alt="youtube link" title="youtube link" />
          </a>
        </div>

        <div className="racelabcredit">
          Racelab made with ❤️ by Pace Engineering GmbH
          <br />
          Zugerstrasse 32
          <br />
          6340 Baar
          <br />
          Switzerland
          <br />
          support@racelab.app
        </div>
      </div>
    </>
  );
}

export default App;
