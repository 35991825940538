import React from "react";

export interface SectionDividerProps {
  className?: string;
}

export const SectionDivider = (props: SectionDividerProps) => {
  return (
    <svg className={`${props.className}`} viewBox="0 0 84 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 0H84L80 8.00758H0L4 0Z" fill="#B70500" />
    </svg>
  );
};
