import "./OverlayPreviews.scss";
import React, { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import standingsOverlay from "../assets/overlays/standings.svg";
import relativeOverlay from "../assets/overlays/relative.svg";
import fuelcalcOverlay from "../assets/overlays/fuelcalc.svg";
import inputTelemetryOverlay from "../assets/overlays/inputtelemetry.svg";
import head2headOverlay from "../assets/overlays/head2head.svg";
import flatmapOverlay from "../assets/overlays/flatmap.svg";
import boostboxOverlay from "../assets/overlays/boostbox.svg";
import flagsOverlay from "../assets/overlays/flags.svg";
import advancedpanelOverlay from "../assets/overlays/advancedpanel.svg";
import datablocksOverlay from "../assets/overlays/datablocks.svg";
import radarOverlay from "../assets/overlays/radar.svg";
import pitboxhelperOverlay from "../assets/overlays/pitboxhelper.svg";

enum OverlayPreviewItems {
  STANDINGS = "STANDINGS",
  RELATIVE = "RELATIVE",
  FUELCALCULATOR = "FUELCALCULATOR",
  INPUTTELEMETRY = "INPUTTELEMETRY",
  HEAD2HEAD = "HEAD2HEAD",
  FLATMAP = "FLATMAP",
  BOOSTBOX = "BOOSTBOX",
  FLAGS = "FLAGS",
  ADVANCEDPANEL = "ADVANCEDPANEL",
  DATABLOCKS = "DATABLOCKS",
  RADAR = "RADAR",
  PITBOXHELPER = "PITBOXHELPER",
}

interface OverlayPreviewMenuItems {
  name: string;
  description: string;
  item: OverlayPreviewItems;
}

export const OverlayPreviews = () => {
  const [overlayInPreview, setOverlayInPreview] = useState<OverlayPreviewItems>(OverlayPreviewItems.STANDINGS);

  const getImage = (item: OverlayPreviewItems) => {
    switch (item) {
      case OverlayPreviewItems.STANDINGS:
        return standingsOverlay;
      case OverlayPreviewItems.RELATIVE:
        return relativeOverlay;
      case OverlayPreviewItems.FUELCALCULATOR:
        return fuelcalcOverlay;
      case OverlayPreviewItems.INPUTTELEMETRY:
        return inputTelemetryOverlay;
      case OverlayPreviewItems.HEAD2HEAD:
        return head2headOverlay;
      case OverlayPreviewItems.FLATMAP:
        return flatmapOverlay;
      case OverlayPreviewItems.BOOSTBOX:
        return boostboxOverlay;
      case OverlayPreviewItems.FLAGS:
        return flagsOverlay;
      case OverlayPreviewItems.ADVANCEDPANEL:
        return advancedpanelOverlay;
      case OverlayPreviewItems.DATABLOCKS:
        return datablocksOverlay;
      case OverlayPreviewItems.RADAR:
        return radarOverlay;
      case OverlayPreviewItems.PITBOXHELPER:
        return pitboxhelperOverlay;
    }
  };

  const menuItems: OverlayPreviewMenuItems[] = [
    {
      item: OverlayPreviewItems.STANDINGS,
      name: "standings",
      description:
        "View general driver positions and data, including car number, pitting info, positions gained or lost, irating, gaps, lap times and much more.",
    },
    {
      item: OverlayPreviewItems.RELATIVE,
      name: "relative",
      description:
        "View general driver relative positions and data, including car number, pitting info, irating, and much more.",
    },
    {
      item: OverlayPreviewItems.FUELCALCULATOR,
      name: "fuel calculator",
      description:
        "Complex calculation on the fly, informing you the consumption of your car, estimated number of laps with the current fuel load, automatic refueling, and more related fuel consumtions.",
    },
    {
      item: OverlayPreviewItems.INPUTTELEMETRY,
      name: "input telemetry",
      description:
        "View your inputs live, learning more about your driving style and perfecting your inputs from being able to see your telemetry immediately as you go.",
    },
    {
      item: OverlayPreviewItems.HEAD2HEAD,
      name: "head 2 head",
      description:
        "Learn your and your opponent sector times. Have the advantage to know where you performing better than your opponents or being able to tell where you need to improve.",
    },
    {
      item: OverlayPreviewItems.FLATMAP,
      name: "flatmap",
      description:
        "View all drivers on the track, being able to tell when drivers are pitting, predicting your estimated position on the track in case of a pitstop.",
    },
    {
      item: OverlayPreviewItems.BOOSTBOX,
      name: "boost box",
      description: "Know your energy consumption, regeneration, see how much energy was deployed in the previous lap.",
    },
    {
      item: OverlayPreviewItems.FLAGS,
      name: "flags",
      description: "View flags that is rased during the race with your customised message.",
    },
    {
      item: OverlayPreviewItems.ADVANCEDPANEL,
      name: "advance panel",
      description: "View your inputs, gears, and certain metrics you would like to see during a race.",
    },
    {
      item: OverlayPreviewItems.DATABLOCKS,
      name: "data blocks",
      description: "Place any data you wish to see on your screen with data blocks.",
    },
    {
      item: OverlayPreviewItems.RADAR,
      name: "radar",
      description:
        "Be aware of your surroundings. This overlay will tell you where your opponents are and will track them while alongside you.",
    },
    {
      item: OverlayPreviewItems.PITBOXHELPER,
      name: "pitbox helper",
      description:
        "Never miss your pitstall again. This overlay will help you navigating in the pits, tracks your tire wear, tire temperatures. Indicates your car speed relative to the pit speed limit, to help you get the maximum out of your pit stops.  ",
    },
  ];

  return (
    <div className="overlaypreviewpanel">
      <div className="panelleft">
        <ul>
          <Scrollbars
            style={{
              height: "100%",
              marginTop: "0em",
              marginBottom: "0em",
            }}
            autoHide
            autoHideTimeout={200}
            autoHideDuration={200}
            thumbSize={300}
            renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
            {menuItems.map((anItem) => {
              return (
                <li
                  key={anItem.name}
                  className={`${anItem.item === overlayInPreview && "active"}`}
                  onClick={() => setOverlayInPreview(anItem.item)}>
                  <div className="overlayname">{anItem.name}</div>
                  <div className="overlaydesc">{anItem.description}</div>
                </li>
              );
            })}
          </Scrollbars>
        </ul>
      </div>
      <div className="panelright">
        <img
          src={getImage(overlayInPreview)}
          alt={`${overlayInPreview} overlay`}
          title={`${overlayInPreview} overlay`}
        />
      </div>
    </div>
  );
};
